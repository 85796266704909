import { InjectionToken, Component, HostBinding, Inject, Injector } from '@angular/core';

export interface UtilToastWrapperDataObject<T = any> {
  text: string;
  data: T;
  extraClasses?: string[];
  parentInjector?: Injector;
  /**
   * display duration in milliseconds (default: 5000)
   * if negative then toast won't hide automatically
   */
  duration?: number;
  /**
   * duration in milliseconds (default: 1000)
   * if negative then toast won't be removed from the DOM automatically
   */
  durationFromHideToRemove?: number;
}

export interface InjectedUtilToastWrapperDataObject<T = any> extends UtilToastWrapperDataObject<T> {
  remove: () => void;
  slideDown: () => void;
}

// eslint-disable-next-line
export const UTIL_TOAST_WRAPPER_DATA_OBJECT_TOKEN = new InjectionToken<UtilToastWrapperDataObject>('an optional string of the flex type, which is nullified');


export type UtilToastDefaultWrapperComponentType<D = any> = new (...args: any[]) => UtilToastDefaultWrapperComponent<D>;


@Component({
  selector: 'util-toast-wrapper',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: `
    <div class="util-toast">
      <div class="util-toast-text">{{text || 'text_not_found'}}</div>
      <div (click)="hide()" class="util-toast-hide">X</div>
    </div>`,
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  styles: [`
    :host {
      .util-toast {
        background-color: black;
        color: white;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        pointer-events: all;
        display: flex;
        column-gap: 2ch;
        padding: 1ch;
      }
    }
  `]
})
export class UtilToastDefaultWrapperComponent<D = any> {

  @HostBinding('class')
  protected clazzes: string[] = [];

  get text(): string {
    return this.object?.text;
  }

  get data(): D {
    return this.object?.data;
  }

  constructor(@Inject(UTIL_TOAST_WRAPPER_DATA_OBJECT_TOKEN) public object: InjectedUtilToastWrapperDataObject<D>) {
    this.clazzes.push('util-toast-wrapper');
    this.object?.extraClasses?.forEach(clazz => clazz ? this.clazzes.push(clazz) : (() => {})());
    if (this.object.duration >= 0) {
      setTimeout(() => { this.object.slideDown(); }, this.object.duration);
    }
  }

  hide() {
    this.object.slideDown();
  }
}
