import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { InjectedUtilToastWrapperDataObject, UtilToastDefaultWrapperComponent, UtilToastDefaultWrapperComponentType, UtilToastWrapperDataObject, UTIL_TOAST_WRAPPER_DATA_OBJECT_TOKEN } from './util-toast-default-wrapper.component';



@Injectable({
  providedIn: 'root'
})
export class UtilToasterService {

  private doc: Document;

  private toaster: HTMLElement;
  private toasts: HTMLElement[] = [];

  constructor(private windowRef: WindowRef, private componentFactoryResolver: ComponentFactoryResolver, private applicationRef: ApplicationRef) {
    this.doc = this.windowRef.document;

    const cssStyleEl = this.doc.createElement('style');

    const style = `

    .util-toaster {

      --extra-bottom-padding: 1ch;

      position: fixed;
      padding: 0px 60px 0px 60px;
      inset: 0;
      top: unset;
      z-index: 100000000;
      pointer-events: none;
      display: flex;
      flex-direction: column-reverse;
      row-gap: 1ch;
    }

    .util-toast-wrapper {
      translate: 0 calc(200% + var(--extra-bottom-padding));
      transition-duration: 150ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0, 0.1);
      transition-delay: 0ms;
      transition-property: all;
      margin-bottom: 2ch;
    }

    .util-toast-wrapper.util-toast-wrapper-up {
      translate: 0 0%;
    }

    `;

    const styleRuleEl = this.doc.createTextNode(style);

    cssStyleEl.appendChild(styleRuleEl);
    this.doc.head.appendChild(cssStyleEl);

  }

  toastMessage<D = any> (
    text: string,
    data?: D,
    parentInjector?: Injector,
    useComponent: UtilToastDefaultWrapperComponentType<D> = UtilToastDefaultWrapperComponent,
    extraClasses?: string[],
    duration?: number,
  ) {

    const obj: UtilToastWrapperDataObject<D> = {
      data,
      text,
      parentInjector,
      extraClasses,
      duration
    };

    this.toast(useComponent, obj);
  }

  toast<D>(
    component: UtilToastDefaultWrapperComponentType<D>,
    obj: UtilToastWrapperDataObject<D>
  ) {

    // eslint-disable-next-line
    obj.duration = obj.duration ?? 5000;
    obj.durationFromHideToRemove = obj.durationFromHideToRemove ?? 1000;

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);

    const inj = Injector.create({
      providers: [{
        provide: UTIL_TOAST_WRAPPER_DATA_OBJECT_TOKEN,
        useValue: obj
      }],
      name: 'util-toast',
      parent: obj.parentInjector
    });

    const componentRef = factory.create(inj);

    this.applicationRef.attachView(componentRef.hostView);
    const componentEl = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    const fnObj = this.addToast(componentEl, obj);
    (obj as InjectedUtilToastWrapperDataObject).remove = fnObj.remove;
    (obj as InjectedUtilToastWrapperDataObject).slideDown = fnObj.slideDown;

  }

  private buildToaster() {
    if (!this.toaster) {
      this.toaster = this.doc.createElement('div');
      this.toaster.classList.add('util-toaster');
      this.doc.body.appendChild(this.toaster);
    }
  }

  private removeToaster() {
    if (this.toaster) {
      this.toaster.parentElement.removeChild(this.toaster);
      this.toaster = void 0;
    }
  }

  private addToast(el: HTMLElement, obj: UtilToastWrapperDataObject): {slideDown: () => void, remove: () => void} {

    this.buildToaster();

    this.toasts.push(el);

    this.toaster?.appendChild(el);

    setTimeout(() => {
      el.classList.add('util-toast-wrapper-up');
    }, 0);

    const remove = () => {
      this.removeToast(el);
    };

    const slideDown = () => {
      el?.classList.remove('util-toast-wrapper-up');
      if (obj.durationFromHideToRemove >= 0) {
        setTimeout(() => {
          remove();
        }, obj.durationFromHideToRemove);
      }
    };

    return {
      slideDown,
      remove
    };

  }

  private removeToast(el: HTMLElement) {
    el?.parentElement?.removeChild(el);
    const i = this.toasts.indexOf(el);
    if (i >= 0) {
      this.toasts.splice(i, 1);
    }

    if (this.toasts.length === 0) {
      this.removeToaster();
    }
  }

}

export class UtilToasterTestingService {

  toastMessage<D = any>(text: string, data?: D, parentInjector?: Injector, useComponent: UtilToastDefaultWrapperComponentType<D> = UtilToastDefaultWrapperComponent) {}

  toast<D>(component: UtilToastDefaultWrapperComponentType<D>, obj: UtilToastWrapperDataObject<D>) {}
}
